import { styled, Tab, Tabs } from '@mui/material'
import { isBoolean, isNumber, isObject, isString, maxBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLocalStorage, useUpdateEffect } from 'react-use'

import { PAGE, trackEvent } from '@/analytics'
import { useIsCurrentUserActivated } from '@/reducers/user'

type SnakeSeparator = '-' | '_'

type ToSnakeCase<T extends string> = T extends `${infer Head}${infer Tail}`
  ? `${Lowercase<Head>}${Lowercase<ToSnakeCase<Tail>>}`
  : T

type ConvertToSnakeCase<
  T extends string,
  S extends SnakeSeparator = '-'
> = T extends `${infer Head} ${infer Tail}`
  ? `${ToSnakeCase<Head>}${S}${ConvertToSnakeCase<Tail>}`
  : ToSnakeCase<T>

type TabLabel = 'Bulk Lookups' | 'Single Searches' | 'Single Verification'

type TabAriaLabel = ConvertToSnakeCase<TabLabel>

export type Tab = {
  index: number
  label: TabLabel
  ariaLabel: TabAriaLabel
  isSingle: boolean
  trackEvent: string
  group: string[]
}

const StyledTabs = styled(Tabs)({
  height: '48px',

  '& .MuiTabs-indicator': {
    height: '4px',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  }
})

const StyledTab = styled(Tab)(({ theme }) => ({
  fill: theme.palette.text.quaternary,
  color: theme.palette.text.quaternary,
  fontWeight: 700,
  py: 0,
  minHeight: '48px',

  '&.Mui-selected': {
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main
  }
}))

export const historyTabs: Tab[] = [
  {
    index: 0,
    label: 'Bulk Lookups',
    ariaLabel: 'bulk-lookups',
    isSingle: false,
    trackEvent: 'Select bulk look up history',
    group: ['address', 'company', 'history']
  },
  {
    index: 1,
    label: 'Single Searches',
    ariaLabel: 'single-searches',
    isSingle: true,
    trackEvent: 'Select single search history',
    group: ['address', 'company', 'history']
  },
  {
    index: 2,
    label: 'Single Verification',
    ariaLabel: 'single-verification',
    isSingle: true,
    trackEvent: 'Select single verification history',
    group: ['verify-and-replace', 'history']
  }
]

const getValidTab = (tab: Tab | undefined) => {
  const isValid =
    isObject(tab) &&
    isNumber(tab.index) &&
    isString(tab.label) &&
    isString(tab.ariaLabel) &&
    isBoolean(tab.isSingle) &&
    isString(tab.trackEvent)

  if (!isValid) return historyTabs[0]

  return tab
}

export const useSelectedTab = (
  forceDefaultIfUserInactive = false,
  fallback: Tab = historyTabs[0]
): [Tab, React.Dispatch<React.SetStateAction<Tab>>] => {
  const [value, setValue] = useLocalStorage<Tab>('selectedTab', fallback)

  const [selectedTab, setSelectedTab] = useState<Tab>(getValidTab(value))
  const isUserActivated = useIsCurrentUserActivated()

  useUpdateEffect(() => {
    setValue(selectedTab)
  }, [selectedTab])

  useEffect(() => {
    if (forceDefaultIfUserInactive && !isUserActivated && selectedTab.index === 0) {
      setSelectedTab(historyTabs[1])
    }
  }, [selectedTab, isUserActivated, forceDefaultIfUserInactive])

  return [selectedTab, setSelectedTab]
}

const AssetTypeTabs = ({
  selectedTab,
  setSelectedTab,
  page
}: {
  selectedTab: Tab
  setSelectedTab: (tab: Tab) => void
  page: PAGE
}) => {
  const location = useLocation()

  const tabs = useMemo(() => {
    const group = location.pathname.replace(/.*\/(.*)$/, '$1')
    const filteredTabs = historyTabs.filter((tab) => tab.group.includes(group))

    const lastTab = maxBy(filteredTabs, 'index')
    if (lastTab && selectedTab.index > lastTab.index) {
      setSelectedTab(filteredTabs[1])
    }

    return filteredTabs
  }, [location.pathname, selectedTab.index, setSelectedTab])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const selectedTab = historyTabs[newValue]

    trackEvent(selectedTab.trackEvent, { page })
    setSelectedTab(selectedTab)
  }

  return (
    <StyledTabs value={selectedTab.index} onChange={handleChange}>
      {tabs.map((tab) => (
        <StyledTab
          key={tab.ariaLabel}
          aria-label={tab.ariaLabel}
          label={tab.label}
          iconPosition="start"
        />
      ))}
    </StyledTabs>
  )
}

export default AssetTypeTabs
