import { ReactNode } from 'react'

import { ReactComponent as BulbIcon } from '@/assets/icons/ic-bulb.svg'
import { ReactComponent as ConfettiIcon } from '@/assets/icons/ic-confetti.svg'
import { ReactComponent as CryingIcon } from '@/assets/icons/ic-crying.svg'
import { ReactComponent as EmailCheckIcon } from '@/assets/icons/ic-email-check.svg'
import { ReactComponent as EmailWarningIcon } from '@/assets/icons/ic-email-warn.svg'
import { ReactComponent as ErrorIcon } from '@/assets/icons/ic-error.svg'
import { ReactComponent as PoitingFingerIcon } from '@/assets/icons/ic-pointing-finger.svg'
import { ReactComponent as QuestionIcon } from '@/assets/icons/ic-question.svg'
import { ReactComponent as SuccessIcon } from '@/assets/icons/ic-success.svg'
import { ReactComponent as UserCheckedIcon } from '@/assets/icons/ic-user-check.svg'

import { ResultControls } from '@/pages/dashboard/QuickMatching/CompanyAssetResults'
import { Asset, VerificationFlag, VerificationFlags } from '@/types/assets'
import type { ReliabilityCard, VerificationFlagObject } from '../types/VerificationFlagObject'
import VerifyAndReplaceResult from './VerifyAndReplaceResult'

const { RED_GREEN_CARD, BLUE_GREEN_CARD, GREEN_CARD, RED_CARD } = VerificationFlags

type VerifyAndReplaceData = Record<VerificationFlag, VerificationFlagObject>

const cards: VerifyAndReplaceData = {
  [RED_GREEN_CARD]: {
    content: (asset: Asset) => ({
      cards: [
        {
          reliability: 'error',
          label: 'Invalid',
          chips: [
            {
              color: 'error',
              icon: EmailWarningIcon,
              label: 'Invalid Email',
              overrideStyle: {
                error: {
                  backgroundColor: '#D42B2B',
                  color: 'white',
                  iconColor: 'fill-current'
                }
              }
            }
          ],
          alert: {
            icon: ErrorIcon,
            assetKey: 'workEmail'
          }
        },
        {
          reliability: 'success',
          label: 'Replacement',
          chips: [
            {
              color: 'success',
              icon: UserCheckedIcon,
              label: 'High Accuracy Match'
            },
            {
              color: 'success',
              icon: EmailCheckIcon,
              label: 'Deliverable'
            }
          ],
          alert: {
            icon: SuccessIcon,
            assetKey: 'enrichedWorkEmail',
            resultControl: (
              <ResultControls result={asset.singleEnrichment.enrichedWorkEmail} isEmail />
            )
          }
        }
      ]
    }),
    header: () => ({
      icon: {
        color: 'info',
        element: PoitingFingerIcon,
        label: 'Replacement Found!'
      },
      text: 'Use our replacement for a better converting email.'
    })
  },
  [BLUE_GREEN_CARD]: {
    content: (asset: Asset) => {
      const cards: ReliabilityCard[] = []
      if (asset.singleEnrichment.enrichedWorkEmail) {
        cards.push(
          {
            reliability: 'error',
            label: 'Probable',
            chips: [
              {
                color: 'info',
                icon: UserCheckedIcon,
                label: 'Medium Accuracy Match'
              },
              {
                color: 'warning',
                icon: EmailWarningIcon,
                label: 'Accept-All'
              }
            ],
            alert: {
              icon: ErrorIcon,
              assetKey: 'workEmail'
            }
          },
          {
            reliability: 'success',
            label: 'Replacement',
            chips: [
              {
                color: 'success',
                icon: UserCheckedIcon,
                label: 'High Accuracy Match'
              },
              {
                color: 'success',
                icon: EmailCheckIcon,
                label: 'Deliverable'
              }
            ],
            alert: {
              icon: SuccessIcon,
              assetKey: 'enrichedWorkEmail'
            }
          }
        )
      } else {
        cards.push({
          reliability: 'info',
          label: 'Probable',
          chips: [
            {
              color: 'info',
              icon: UserCheckedIcon,
              label: 'Medium Accuracy Match'
            },
            {
              color: 'warning',
              icon: EmailWarningIcon,
              label: 'Accept-All'
            }
          ],
          alert: {
            icon: QuestionIcon,
            assetKey: 'workEmail'
          }
        })
      }

      return { cards }
    },
    header: (asset: Asset) => {
      const { enrichedWorkEmail } = asset.singleEnrichment

      const element = enrichedWorkEmail ? PoitingFingerIcon : BulbIcon
      const label = enrichedWorkEmail ? 'Replacement Found!' : 'Possibly Correct.'
      const text = enrichedWorkEmail
        ? 'Use our replacement for a better converting email.'
        : `The email provided might be correct, but we couldn’t find a better one for ${asset.singleEnrichment.firstName} ${asset.singleEnrichment.lastName}.`

      return {
        icon: {
          color: 'info',
          element,
          label
        },
        text
      }
    }
  },
  [GREEN_CARD]: {
    content: () => ({
      cards: [
        {
          reliability: 'success',
          label: 'Accurate',
          chips: [
            {
              color: 'success',
              icon: UserCheckedIcon,
              label: 'High Accuracy Match'
            },
            {
              color: 'success',
              icon: EmailCheckIcon,
              label: 'Deliverable'
            }
          ],
          alert: {
            icon: SuccessIcon,
            assetKey: 'workEmail'
          }
        }
      ]
    }),
    header: (asset: Asset) => ({
      icon: {
        color: 'success',
        element: ConfettiIcon,
        label: 'Congratulations!'
      },
      text: `You have the correct email address for ${asset.singleEnrichment.firstName} ${asset.singleEnrichment.lastName}.`
    })
  },
  [RED_CARD]: {
    content: () => ({
      cards: [
        {
          reliability: 'error',
          label: 'Invalid',
          chips: [
            {
              color: 'error',
              icon: EmailWarningIcon,
              label: 'Invalid Email',
              overrideStyle: {
                error: {
                  backgroundColor: '#D42B2B',
                  color: 'white',
                  iconColor: 'fill-current'
                }
              }
            }
          ],
          alert: {
            icon: ErrorIcon,
            assetKey: 'workEmail'
          }
        }
      ]
    }),
    header: (asset: Asset) => {
      return {
        icon: {
          color: 'error',
          element: CryingIcon,
          label: 'Invalid Email.'
        },
        text: `This is an invalid email, and we couldn’t find a better one for ${asset.singleEnrichment.firstName} ${asset.singleEnrichment.lastName}.`
      }
    }
  }
}

const SingleVerificationResult = ({ asset, children }: { asset: Asset; children: ReactNode }) => {
  const data = cards[asset.singleEnrichment.verification]

  if (!data) return null

  return (
    <VerifyAndReplaceResult asset={asset} container={data}>
      {children}
    </VerifyAndReplaceResult>
  )
}

export default SingleVerificationResult
