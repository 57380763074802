import { useState } from 'react'
import Container from '@mui/material/Container'
import { Grid, Typography } from '@mui/material'
import UploadModal from '../../components/Pages/Dashboard/UploadModal'
import './Dashboard.scss'
import Banners from './Banners'
import ExplorePricing from './ExplorePricing'

const DashboardLayout = ({
  title,
  shortTitle,
  headerButton,
  children
}: {
  title: string
  shortTitle: string
  headerButton: React.ReactNode
  children: React.ReactNode
}) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Banners page={'Dashboard'} />
      <Container
        className="dashboard-page px-2 md:pr-12 py-4 md:py-10"
        disableGutters
        maxWidth="xl"
      >
        <div className="flex items-center flex-wrap justify-between gap-y-4 gap-x-2">
          <Typography variant="h1" color="primary.main" className="hidden md:block">
            {title}
          </Typography>
          <Typography variant="h2" color="primary.main" className="md:hidden">
            {shortTitle}
          </Typography>
          <div className="flex items-center flex-wrap gap-y-3 gap-x-2">
            <Typography
              variant="body1"
              color="text.secondary"
              fontWeight={700}
              className="hidden md:block"
            >
              Looking for something else?
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              fontWeight={700}
              className="md:hidden"
            >
              ... or you can always
            </Typography>
            {headerButton}
          </div>
        </div>
        <div className="bg-body main-section">
          <Grid container spacing={3} mb={4}>
            {children}
          </Grid>
        </div>
        <UploadModal visible={visible} onCloseModal={() => setVisible(false)} />
        <ExplorePricing />
      </Container>
    </>
  )
}

export default DashboardLayout
