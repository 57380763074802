import { Box } from '@mui/material'

interface TabPanelProps {
  children: React.ReactNode
  selectedTab: number
}

const TabPanel = ({ children, selectedTab }: TabPanelProps) => {
  return (
    <Box role="tabpanel" id={`tabpanel-${selectedTab}`} aria-labelledby={`tab-${selectedTab}`}>
      {children}
    </Box>
  )
}

export default TabPanel
