import { Container, Typography } from '@mui/material'

import { PageSubtitle, PageTitle } from '@/components/common/Pages'
import { VerifyTabs } from '@/components/VerifyAndReplace/Tabs'
import HistoryTabs from '@/components/VerifyAndReplace/Tabs/HistoryTabs'
import useTrackVerifyAndReplace from './hooks/useTrackVerifyAndReplace'
import Banners from '@/pages/dashboard/Banners'

const PAGE_TITLE = 'Verify & Replace Work Email'
const PAGE_SUBTITLE =
  'Check the quality of your existing work emails, and update the ones where Archer can improve the accuracy.'

const TABS_TITLE = 'Recent verifications'

const VerifyAndReplace = () => {
  useTrackVerifyAndReplace()({ eventName: 'VF - Views Page' })

  return (
    <>
      <Banners page="Verify & Replace - Single" />
      <Container
        className="dashboard-page px-2 md:pr-12 py-4 md:py-10"
        disableGutters
        maxWidth="xl"
      >
        <PageTitle>{PAGE_TITLE}</PageTitle>
        <PageSubtitle>{PAGE_SUBTITLE}</PageSubtitle>
        <VerifyTabs />
        <Typography className="mt-8" variant="h2">
          {TABS_TITLE}
        </Typography>
        <HistoryTabs />
      </Container>
    </>
  )
}

export default VerifyAndReplace
