import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { filesize } from 'filesize'

import { ReactComponent as CheckedIcon } from '@/assets/icons/green-circle-check.svg'
import { useFormContext } from 'react-hook-form'
import { Inputs } from './types'
import { useEffect } from 'react'

interface Props {
  onRemoveFile: () => void
  selectedFile: File
}

const ListSelectedFiles = ({ onRemoveFile, selectedFile }: Props) => {
  const { clearErrors } = useFormContext<Inputs>()

  useEffect(() => {
    clearErrors('file')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { name, size } = selectedFile

  return (
    <List>
      <ListItem alignItems="flex-start">
        <ListItemIcon
          sx={{
            minWidth: '1.3rem'
          }}
        >
          <CheckedIcon />
        </ListItemIcon>
        <ListItemText
          className="mt-[2px]"
          primary={<strong>{name}</strong>}
          secondary={filesize(size, { standard: 'jedec' })}
        />
        <IconButton aria-label="delete" onClick={onRemoveFile}>
          <ClearIcon className="size-4" />
        </IconButton>
      </ListItem>
    </List>
  )
}

export default ListSelectedFiles
