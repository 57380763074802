import { Typography } from '@mui/material'

const SignUpSideScreen = () => {
  return (
    <div className="flex-1 bg-light flex-col gap-14 flex items-center justify-center">
      <img src="/assets/images/logo-su.svg" alt="Hero" />
      <img src="/assets/images/img-signup.svg" alt="Hero-signup" />
      <Typography
        color="primary.main"
        fontWeight={700}
        fontSize={24}
        textAlign="center"
        className="mx-6"
      >
        Spend your time selling instead of learning the data industry
      </Typography>
    </div>
  )
}

export default SignUpSideScreen
