import { IconWithText, SxIconWithText } from '@/components/common/IconWithText'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import {
  Alert,
  alertClasses,
  Box,
  BoxProps,
  Stack,
  styled,
  SvgIcon,
  SxProps,
  Theme,
  Typography
} from '@mui/material'
import { Fragment, ReactNode } from 'react'
import { Link } from 'react-router-dom'

import ReliabilityLabel, { reliabilities, Reliability } from '../HistoryTable/ReliabilityLabel'

import { ReactComponent as BuildingIcon } from '@/assets/icons/ic-building.svg'
import { ReactComponent as IcCircleInfo } from '@/assets/icons/ic-circle-info.svg'

import { Asset } from '@/types/assets'
import { ucFirst } from '@/utils/str'
import Chip from './Chip'
import { VerificationFlagObject } from '../types/VerificationFlagObject'
import { TooltipFullText } from '@/components/common/TooltipFullText'

interface StyledBox extends BoxProps {
  reliability: Reliability
}

const getReliabilityColor = (reliability: Reliability, alpha = 1) =>
  `rgba(${reliabilities[reliability]}, ${alpha})`

const styles: Partial<Record<keyof SxIconWithText, SxProps<Theme>>> = {
  text: {
    fontSize: '14px',
    fontWeight: '700'
  },
  icon: {
    height: '1rem',
    width: '1rem'
  }
}

const { text, icon } = styles

const StyledAlert = styled(Alert, {
  shouldForwardProp: (prop) => prop !== 'bgColor'
})<{ bgColor?: string }>(({ severity, bgColor }) => {
  const color = bgColor ?? getReliabilityColor(severity as Reliability, 0.3)

  return {
    backgroundColor: color,
    color: 'black',
    [`.${alertClasses.icon}`]: {
      marginRight: 5
    }
  }
})

const StyledResultContainer = styled(Box)<StyledBox>(({ reliability }) => {
  const color = getReliabilityColor(reliability)

  return {
    maxWidth: '476px',
    height: '128px',
    borderRadius: '12px',
    borderLeft: '16px',
    borderRight: '1px',
    borderTop: '1px',
    borderBottom: '1px',
    borderStyle: 'solid',
    borderColor: color,
    padding: '1rem',
    paddingTop: '0.75rem'
  }
})

const VerifyAndReplaceResult = ({
  asset,
  children,
  container
}: {
  asset: Asset
  children: ReactNode
  container: VerificationFlagObject
}) => {
  const { firstName, lastName, company } = asset.singleEnrichment

  const fullName = firstName + ' ' + lastName
  const companyName = ucFirst(company)

  const header = container.header(asset)
  const content = container.content(asset)

  const headerIconTextColor = getReliabilityColor(header.icon.color)

  const { cards } = content

  return (
    <Stack gap={5}>
      <Stack direction="row" gap={1}>
        <IconWithText
          icon={header.icon.element}
          style={{
            text: { ...text, color: headerIconTextColor, fontSize: '1rem' },
            icon: { ...icon, color: headerIconTextColor }
          }}
        >
          {header.icon.label}
        </IconWithText>
        {header.text}
      </Stack>

      <Stack direction="row" gap={5} alignItems="center">
        {cards.map((card, index) => {
          const CardAlertIcon = card.alert.icon
          const { chips, reliability } = card

          return (
            <Fragment key={index}>
              <StyledResultContainer reliability={reliability} flexGrow={1}>
                <Stack direction="row">
                  <Box alignItems="center" className="flex" flex={1} gap={1}>
                    <Typography variant="h6" className="text-base">
                      <TooltipFullText className="text-black" limit={10}>
                        {fullName}
                      </TooltipFullText>
                    </Typography>
                    |
                    <IconWithText
                      icon={BuildingIcon}
                      style={{
                        text: {
                          fontSize: '1rem',
                          fontWeight: 'normal'
                        },
                        icon: { marginTop: 'auto', width: 16, height: 33 }
                      }}
                    >
                      <TooltipFullText className="text-black" limit={22}>
                        {companyName}
                      </TooltipFullText>
                    </IconWithText>
                  </Box>
                  <Box>
                    <ReliabilityLabel label={card.label} reliability={reliability} />
                  </Box>
                </Stack>
                <Box className="mt-2">
                  <Box className="flex gap-4">
                    <StyledAlert
                      bgColor={card.alert.bgColor}
                      className="mt-0 px-2 py-0 font-bold text-base inline-flex items-center h-8"
                      icon={<CardAlertIcon />}
                      severity={reliability}
                    >
                      {asset.singleEnrichment[card.alert.assetKey]}
                    </StyledAlert>
                    {card.alert.resultControl}
                  </Box>
                  <Box className="mt-2 flex gap-2">
                    {chips.map((chip, index) => (
                      <Chip
                        key={index}
                        color={chip.color}
                        icon={chip.icon}
                        label={chip.label}
                        overrideStyle={chip.overrideStyle}
                      />
                    ))}
                  </Box>
                </Box>
              </StyledResultContainer>
              {index === 0 && content.cards.length > 1 && (
                <Box>
                  <SvgIcon component={TrendingFlatIcon} fontSize="large" color="primary" />
                </Box>
              )}
            </Fragment>
          )
        })}
      </Stack>

      <Box className="flex justify-between">
        <Box className="flex items-center gap-2">
          <IcCircleInfo className="flex-shrink-0" />
          <Typography variant="body1" fontWeight={500}>
            You can later find all your enrichments on the&nbsp;
            <Link to="/history">
              <Box component="span" className="text-primary.main" fontWeight={700}>
                History
              </Box>
            </Link>
            &nbsp;page.
          </Typography>
        </Box>
        <Box className="flex flex-col items-center">{children}</Box>
      </Box>
    </Stack>
  )
}

export default VerifyAndReplaceResult
