import { Tabs } from '@/components/common/Tabs'

import { ReactComponent as BulkEnrichmentIcon } from '@/assets/icons/ic-bulk-enrichments.svg'
import { ReactComponent as SingleEnrichmentIcon } from '@/assets/icons/ic-single-enrichment.svg'

import { ProminentTabsList } from '@/components/common/Tabs/navigation-styles/ProminentTabsList'
import { TabType } from '@/components/common/Tabs/types/tab.type'
import BulkVerification from '../BulkVerification/BulkVerification'
import SingleVerificationContainer from '../SingleVerification/SingleVerificationContainer'

const tabs: TabType[] = [
  {
    content: () => <BulkVerification />,
    icon: BulkEnrichmentIcon,
    label: 'Bulk Verification'
  },
  {
    content: () => <SingleVerificationContainer />,
    icon: SingleEnrichmentIcon,
    label: 'Single Verification'
  }
]

const className = {
  body: 'shadow-lg',
  container: 'w-full flex flex-col gap-4 overflow-visible relative'
}

const VerifyTabs = () => <Tabs className={className} navigateTabs={ProminentTabsList} tabs={tabs} />

export default VerifyTabs
