/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { object } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import FormInput from '../../components/Form/Input'
import { requestMagicLink } from '@/services/auth'
import { emailValidator } from '@/utils/validators'
import { useState } from 'react'
import { Alert, Box, Divider, Button, Typography, styled, useTheme } from '@mui/material'
import SignUpSideScreen from './sideScreen/SignUpSideScreen'
import { ReactComponent as EmailSentIcon } from '@/assets/icons/ic-email-sent.svg'
import BackLink from '@/components/Links/BackLink'
import BaseLayout from './BaseLayout'

interface FormValues {
  email: string
}

const schema = object({
  email: emailValidator
}).required()

const StyledEmailSentIcon = styled(EmailSentIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
  display: 'block',
  margin: '0 auto'
}))

const MagicLinkRequestPage = () => {
  const { control, handleSubmit, formState } = useForm<FormValues>({
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema)
  })
  const theme = useTheme()
  const { isValid, errors } = formState
  const [serverError, setServerError] = useState<string | undefined>(undefined)
  const [completed, setCompleted] = useState(false)
  const [linkResent, setLinkResent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [emailAddress, setEmailAddress] = useState<string | undefined>(undefined)

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setLoading(true)
      await requestMagicLink(values.email)
      setCompleted(true)
      setEmailAddress(values.email)
      setLoading(false)
    } catch (error) {
      // @ts-ignore
      setServerError(error?.response?.data?.error)
    }
  }

  return (
    <BaseLayout sideScreen={<SignUpSideScreen />}>
      {!completed ? (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Typography variant="h1">Login</Typography>
          <Divider className="mt-4 mb-8" />

          <Typography fontWeight={700} textAlign={'center'} mt={12} mb={5}>
            Get a magic link sent to your email address that will sign you in instantly!
          </Typography>

          <FormInput
            control={control}
            name="email"
            type="email"
            label="Email address"
            controlId="fieldEmailAddress"
            rules={{ required: true }}
            error={errors?.email?.message}
          />

          {!!serverError && <Alert severity="error">{serverError}</Alert>}

          <Button
            variant="contained"
            className="w-100 mt-10"
            type="submit"
            disabled={loading || !isValid}
            size="large"
          >
            Send magic link
          </Button>
          <Box mt={6} mb={5} sx={{ color: theme.palette.text.secondary }}>
            <Divider>
              <b>OR</b>
            </Divider>
          </Box>
          <div className="text-center">
            <Link to="/auth/sign-in" style={{ textDecoration: 'none' }}>
              Type password instead
            </Link>
          </div>
        </form>
      ) : null}
      {completed ? (
        <div className="w-100 h-100 d-flex flex-column justify-content-between">
          <form onSubmit={handleSubmit(handleFormSubmit)} className="w-100">
            <Typography variant="h1">Magic link sent</Typography>
            <Divider className="mt-4 mb-8" />
          </form>
          <Box mb={8}>
            <Box ml="auto" mr="auto" mb={3}>
              <StyledEmailSentIcon />
            </Box>
            <Typography textAlign={'center'} variant="h3" mb={2}>
              Check your email inbox!
            </Typography>
            <Typography>
              We sent you an email that has a magic link in it to sign you in.
              <br />
              <br />
              If you don&apos;t receive the email within a few minutes, check your spam folder.
            </Typography>
            <br />
            <br />
            {emailAddress && !linkResent ? (
              <Typography textAlign={'center'}>
                Still haven&apos;t received it? Click&nbsp;
                {loading ? (
                  'here'
                ) : (
                  <a
                    role="button"
                    href={`/auth/magic-link/request?email=${emailAddress}`}
                    onClick={async (event) => {
                      event.preventDefault()
                      event.stopPropagation()
                      setLoading(true)
                      await requestMagicLink(emailAddress)
                      setLinkResent(true)
                      setLoading(false)
                    }}
                  >
                    here
                  </a>
                )}
                &nbsp;to resend.
              </Typography>
            ) : null}
            {linkResent ? (
              <Typography textAlign={'center'}>We&apos;ve resent a link</Typography>
            ) : null}
          </Box>
          <div className="d-flex align-items-center justify-content-center">
            <BackLink>Back to Login</BackLink>
          </div>
        </div>
      ) : null}
    </BaseLayout>
  )
}

export default MagicLinkRequestPage
