import ClearIcon from '@mui/icons-material/Clear'
import { Box, Button, IconButton, Modal, Stack, SxProps, Theme } from '@mui/material'

import { IconWithText, SxIconWithText } from '@/components/common/IconWithText'
import { PageSubtitle, PageTitle } from '@/components/common/Pages'
import { useState } from 'react'
import useTrackVerifyAndReplace from '../BulkVerification/hooks/useTrackVerifyAndReplace'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 960,
  minWidth: 960,
  bgcolor: 'background.paper',
  p: 3,
  borderRadius: '30px'
}

// TODO: Find a better way to define the styles using styled
const styles: Record<keyof SxIconWithText, SxProps<Theme>> = {
  text: {
    color: (theme) => theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '700'
  },
  icon: {
    color: (theme) => theme.palette.primary.main,
    height: '1rem',
    width: '1rem'
  },
  container: null
}

const { text, icon } = styles

const ResultPreviewModal = () => {
  const [open, setOpen] = useState(false)

  const trackEvent = useTrackVerifyAndReplace()

  const handleOpen = () => {
    setOpen(true)
    trackEvent({ eventName: 'Clicks - What can I expect' })
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button onClick={handleOpen}>
        <IconWithText style={{ text, icon }}>What kind of results can I expect?</IconWithText>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction="row">
            <PageTitle className="flex-grow text-2xl text-text.primary">Result preview</PageTitle>
            <IconButton onClick={handleClose}>
              <ClearIcon />
            </IconButton>
          </Stack>
          <PageSubtitle>
            Once you perform Verify & Replace on a single email address, you can expect outputs
            similar to these:
          </PageSubtitle>
          <Box className="mt-4">
            <Box
              component="img"
              sx={{
                height: '100%',
                width: '100%'
              }}
              alt="What to expect from verify and replace results."
              src="/result-preview.webp"
            />
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default ResultPreviewModal
