import useTrackVerifyAndReplace from '@/components/VerifyAndReplace/BulkVerification/hooks/useTrackVerifyAndReplace'
import { Alert, Box, Card } from '@mui/material'
import { useState } from 'react'
import TabPanel from './TabPanel'
import { TabButton } from './types/buttons.type'
import { TabType } from './types/tab.type'

interface TabsProps {
  className?: {
    body?: string
    container?: string
  }
  tabs: TabType[]
  navigateTabs: React.FunctionComponent<TabButton>
}

const DEFAULT_SELECTED_TAB = 1

const Tabs = ({
  className: { body, container } = {},
  tabs,
  navigateTabs: NavigateTabs
}: TabsProps) => {
  const countTabs = tabs.length
  const defaultSelectedTab = countTabs === 1 ? 0 : DEFAULT_SELECTED_TAB

  // TODO: The tracking parameters should be defined from the outside
  const trackEvent = useTrackVerifyAndReplace()
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    trackEvent({
      eventName: 'Clicks - Bulk VF Tabs',
      tab: tabs[newValue].label
    })

    setSelectedTab(newValue)
  }

  if (countTabs === 0)
    return (
      <Alert className="mt-4" severity="warning">
        No tabs available
      </Alert>
    )

  return (
    <Box className={container}>
      <NavigateTabs tabs={tabs} onChange={handleChange} selectedTab={selectedTab} />
      <Card className={`p-6 overflow-visible ${body} text-sm`}>
        <TabPanel selectedTab={selectedTab}>{tabs[selectedTab].content()}</TabPanel>
      </Card>
    </Box>
  )
}

export default Tabs
