import { useSearchParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { getLocale } from './locale'
import { useEffect, useState } from 'react'
import { PricingSchema } from '@/types/pricing'

export const calculateMatchPrice = (matchNum: number, pricingSchema: PricingSchema[]) => {
  for (let i = 0; i < pricingSchema.length; i++) {
    const priceRange = pricingSchema[i]
    if (matchNum >= priceRange.floor && matchNum <= priceRange.ceiling) {
      return priceRange.price
    }
  }

  return 0
}

export const calculatePriceWithOffset = (
  numMatches: number,
  pricingSchema: PricingSchema[],
  offset = 0,
  freeCredits = 0
): number => {
  let totalPrice = 0
  const trueOffset = Math.max(offset, 0)
  const remainingMatches = numMatches - freeCredits

  for (let i = 0; i < pricingSchema.length; i++) {
    const pricing = pricingSchema[i]

    const floor = Math.max(pricing.floor - trueOffset, 1)
    const ceiling = Math.max(pricing.ceiling - trueOffset, 0)

    const price = pricing.price
    const numberOfMatches =
      floor <= remainingMatches ? Math.min(remainingMatches - floor + 1, ceiling - floor + 1) : 0

    totalPrice += numberOfMatches * price
  }

  return totalPrice
}

export const humanFileSize = (bytes: number, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + ' ' + units[u]
}

export const formatNumber = (number?: number) =>
  new Intl.NumberFormat(getLocale()).format(number || 0)

export const formatMoney = (
  number?: number,
  {
    currency = 'USD',
    notation = undefined
  }: {
    currency?: string
    notation?: 'standard' | 'scientific' | 'engineering' | 'compact' | undefined
  } = {}
) => {
  const isInteger = Number.isInteger(number)
  const options: Intl.NumberFormatOptions = {
    currency,
    minimumFractionDigits: isInteger ? 0 : 2,
    notation,
    style: 'currency'
  }

  return new Intl.NumberFormat(getLocale(), options).format(number || 0)
}

export const useClearSearchParam = (param: string, value: string) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const isSuccess = searchParams.get(param) === value
  const [wasSet, setWasSet] = useState(false)

  useEffect(() => {
    if (isSuccess) {
      searchParams.delete(param)
      setSearchParams(searchParams)
      setWasSet(true)
    }
  }, [isSuccess])

  return wasSet
}

export const useGetAndClearSearchParam = (param: string) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamValue = searchParams.get(param)
  const [previousValue, setPreviousValue] = useState<string | null>(null)

  useEffect(() => {
    if (searchParamValue) {
      searchParams.delete(param)
      setSearchParams(searchParams)
      setPreviousValue(searchParamValue)
    }
  }, [searchParamValue])

  return previousValue
}

export const formatDate = (date: string, format = DateTime.DATE_FULL) => {
  return DateTime.fromISO(date).toLocaleString(format)
}
