import { useCallback, useEffect, useState } from 'react'

import { useVerifyAndReplaceAssets } from '@/pages/asset/hooks'
import { Asset, AssetStatus } from '@/types/assets'

const useProcessingAsset = (asset: Asset | undefined) => {
  const { refetch } = useVerifyAndReplaceAssets()
  const [isProcessing, setIsProcessing] = useState(false)
  const startProcessing = useCallback(() => setIsProcessing(true), [setIsProcessing])

  const isReady = asset && AssetStatus.READY === asset.status

  useEffect(() => {
    if (!isReady) return

    refetch()
    setIsProcessing(false)
  }, [isReady, refetch])

  return {
    isProcessing,
    isReady,
    startProcessing
  }
}

export default useProcessingAsset
