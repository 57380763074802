import { Button, Grid } from '@mui/material'
import QuickEnrich from './QuickMatching/QuickEnrich'
import RecentFiles from './RecentFiles'
import { useAuthGuardPageTracker } from '@/analytics'
import DashboardLayout from './DashboardLayout'

const trackingPayload = { page: 'Dashboard', lookup: 'company' } as const

const DashboardCompanyPage = () => {
  useAuthGuardPageTracker('Visits Dashboard Page', trackingPayload)

  return (
    <DashboardLayout
      title="Find people by their company"
      shortTitle="Find people by company"
      headerButton={
        <Button href="/lookup/address" size="small" variant="outlined">
          Find contact info by address
        </Button>
      }
    >
      <Grid item xs={12}>
        <QuickEnrich matchType="company" />
      </Grid>
      <Grid item xs={12} className="hidden md:block">
        <RecentFiles />
      </Grid>
    </DashboardLayout>
  )
}

export default DashboardCompanyPage
