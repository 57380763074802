import { TabArrow } from '@/pages/dashboard/QuickMatching/Tabs'

interface TabNavigationArrowProps {
  countTabs: number
  currentTab: number
}

const TabNavigationArrow = ({ countTabs, currentTab }: TabNavigationArrowProps) => {
  const isFirstTab = currentTab === 0
  const isLastTab = currentTab === countTabs - 1

  const position = isFirstTab ? 'left' : isLastTab ? 'right' : ''

  return <TabArrow position={position} />
}

export default TabNavigationArrow
