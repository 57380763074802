import { trackEvent, TYPE } from '@/analytics'
import { useAuth } from '@/reducers/user'

type TrackEvent = {
  eventName: TYPE
  [key: string]: unknown
}

const useTrackVerifyAndReplace = () => {
  const { user } = useAuth()

  return ({ eventName, ...otherProps }: TrackEvent) => {
    trackEvent(eventName, {
      ...otherProps,
      time: new Date().getTime(),
      user: { fullName: user?.fullName, email: user?.email }
    })
  }
}

export default useTrackVerifyAndReplace
