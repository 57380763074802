import { Box } from '@mui/material'
import ImportDocumentDropzoneCard from './ImportDocumentDropzoneCard'

const DropzoneFile = () => {
  return (
    <>
      <ImportDocumentDropzoneCard />
      <Box className="text-gray-400">Accepted formats include .csv, .xls, and .xlsx.</Box>
    </>
  )
}

export default DropzoneFile
