import { Button, ButtonProps, PaletteColor, styled } from '@mui/material'
import { useFormContext } from 'react-hook-form'

interface SubmitButtonProps extends ButtonProps {
  children: string
  variant?: ButtonProps['variant']
  paletteColorKey?: keyof PaletteColor
}

const ButtonStyle = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'paletteColorKey'
})<SubmitButtonProps>(({ theme, paletteColorKey = 'main' }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  paddingLeft: '8px 24px',
  backgroundColor: theme.palette.primary[paletteColorKey]
}))

const SubmitButton = ({
  children,
  paletteColorKey = 'main',
  variant = 'contained',
  disabled
}: SubmitButtonProps) => {
  const form = useFormContext()
  if (!form) {
    throw new Error('SubmitButton must be used inside a FormProvider')
  }

  const { isSubmitting } = form.formState

  return (
    <ButtonStyle
      disabled={disabled || isSubmitting}
      paletteColorKey={paletteColorKey}
      size="large"
      type="submit"
      variant={variant}
    >
      {children}
    </ButtonStyle>
  )
}

export default SubmitButton
