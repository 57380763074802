import { useState, useCallback } from 'react'
import { Card } from '@mui/material'
import { DropzoneOptions, useDropzone } from 'react-dropzone'

import DropzoneImportDocument from './DropzoneImportDocument'
import ListSelectedFiles from './ListSelectedFiles'
import { ACCEPTED_FILE_TYPES, MAX_FILE_SIZE, MAX_SELECTED_FILES } from './constants/upload-files'
import { useFormContext } from 'react-hook-form'
import { Inputs } from './types'

type SelectedFile = File | null

const ImportDocumentDropzoneCard = () => {
  const [selectedFile, setSelectedFile] = useState<SelectedFile>(null)

  const {
    formState: { errors },
    setValue
  } = useFormContext<Inputs>()

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setSelectedFile(acceptedFiles[0])

    setValue('file', acceptedFiles[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const options: DropzoneOptions = {
    onDrop,
    maxSize: MAX_FILE_SIZE,
    maxFiles: MAX_SELECTED_FILES,
    accept: ACCEPTED_FILE_TYPES
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone(options)

  const removeSelectedFile = () => {
    setSelectedFile(null)
  }

  const content = () =>
    !selectedFile ? (
      <DropzoneImportDocument
        fileRejections={fileRejections}
        getInputProps={getInputProps}
        getRootProps={getRootProps}
      />
    ) : (
      <ListSelectedFiles onRemoveFile={removeSelectedFile} selectedFile={selectedFile} />
    )

  const borderColor = errors.file ? 'border-red-200' : 'border-gray-100'

  return (
    <Card
      className={`border-dashed ${borderColor} mb-2 p-[24px] text-center text-gray-500 shadow-none`}
    >
      {content()}
    </Card>
  )
}

export default ImportDocumentDropzoneCard
