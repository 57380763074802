import { Box, Button } from '@mui/material'
import useTrackVerifyAndReplace from '../VerifyAndReplace/BulkVerification/hooks/useTrackVerifyAndReplace'

interface ViewAllHistoryButtonProps {
  href?: string
}

const ViewAllHistoryButton = ({ href = '/history' }: ViewAllHistoryButtonProps) => {
  const trackEvent = useTrackVerifyAndReplace()

  const handleClick = () => {
    trackEvent({ eventName: 'Clicks - View All History' })
  }

  return (
    <Box className="mt-6">
      <Button variant="outlined" size="large" href={href} onClick={handleClick}>
        View All History
      </Button>
    </Box>
  )
}

export default ViewAllHistoryButton
