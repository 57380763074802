import { AxiosError } from 'axios'
import { Box, Grid, styled } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { Apis } from '@/providers/ApiProvider'
import { processAsset } from '@/services/asset'
import { yupResolver } from '@hookform/resolvers/yup'
import AdvancedSearch from './AdvancedSearch'
import FormInput from '@/components/Form/Input/Input'
import SubmitButton from '@/components/common/Form/SubmitButton'
import { trackEvent } from '@/analytics'
import { useIsCurrentUserActivated } from '@/reducers/user'

export interface FormData {
  company: string
  fullName: string
  industry?: string
  location?: string
  workEmail: string
}

interface SingleVerificationFormProps {
  onResult: (result: string) => void
}

const schema = yup.object({
  company: yup.string().required('Company Name is required'),
  fullName: yup.string().required('Full Name is required'),
  workEmail: yup.string().email('Work email is invalid').required('Work Email is required')
})

const GridSyle = styled(Grid)(({ theme }) => ({
  '& label': {
    color: theme.palette.text.quaternary,
    fontSize: 12,
    paddingBottom: '0.5rem !important',
    textTransform: 'uppercase'
  }
}))

const SingleVerificationForm = ({ onResult }: SingleVerificationFormProps) => {
  const navigate = useNavigate()
  const isActivated = useIsCurrentUserActivated()

  const form = useForm<FormData>({
    resolver: yupResolver(schema)
  })

  const onSubmit = async (formData: FormData) => {
    trackEvent('Clicks - Verify & Replace', {
      email: formData.workEmail,
      fullName: formData.fullName,
      company: formData.company,
      industry: formData.industry ?? '',
      location: formData.location ?? ''
    })

    try {
      const { data } = await Apis.postAssetVerifyAndReplace({
        filename: `${formData.fullName}`,
        requestedType: 'verify-replace-work',
        requestWorkEmails: true,
        verifyReplace: true,
        rows: [formData]
      })

      await processAsset({ assetId: data.asset._id, navigate })

      onResult(data.asset._id)
    } catch (error) {
      if (error instanceof AxiosError) {
        form.setError('workEmail', {
          type: 'manual',
          message: error.response?.data?.error || 'An error occurred'
        })
      }

      console.error(error)
    }
  }

  const { control, handleSubmit, formState } = form

  return (
    <FormProvider {...form}>
      <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
        <GridSyle container spacing={2} alignItems="start">
          <Grid item xs={12} sm={4}>
            <FormInput
              className="w-full"
              control={control}
              controlId="fieldWorkEmail"
              formState={formState}
              label="Work Email"
              name="workEmail"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInput
              className="w-full"
              control={control}
              controlId="fieldFullName"
              formState={formState}
              label="Full Name"
              name="fullName"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInput
              className="w-full"
              control={control}
              controlId="fieldCompany"
              formState={formState}
              label="Company Name"
              name="company"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <AdvancedSearch />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <SubmitButton disabled={!isActivated}>Verify & Replace</SubmitButton>
            </Box>
          </Grid>
        </GridSyle>
      </form>
    </FormProvider>
  )
}

export default SingleVerificationForm
