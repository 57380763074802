import { trackEvent, useAuthGuardPageTracker } from '@/analytics'
import UploadFileStep from '@/pages/upload/step-components/upload-file'
import { useNavigate } from 'react-router-dom'
import BulkUploadLayout from '../BulkLayout'
import { handleFlatfileUploadComplete, isLimitExceededError, useRedirectIfInactive } from '../utils'

const trackingPayload = { page: 'Dashboard', lookup: 'company' } as const

const BulkUploadCompanyPage = () => {
  useAuthGuardPageTracker('Starts FlatFile flow', trackingPayload)
  const navigate = useNavigate()

  useRedirectIfInactive()

  const handleClickNext = () => Promise.resolve()

  const handleComplete = async (newFilename: string, records?: any, batchId?: string) => {
    try {
      await handleFlatfileUploadComplete({
        newFilename,
        records,
        batchId,
        requestPayload: {
          requestWorkEmails: true,
          requestedType: 'company'
        },
        navigate
      })
      trackEvent('Completes FlatFile flow', { page: 'Enrich - Bulk', type: 'company' })
    } catch (error) {
      if (isLimitExceededError(error)) {
        navigate(`/let-us-handle/contact`, { state: { batchId } })
      }
    }
  }

  return (
    <BulkUploadLayout handleComplete={handleComplete}>
      <UploadFileStep onNext={handleClickNext} onComplete={handleComplete} type="company" />
    </BulkUploadLayout>
  )
}

export default BulkUploadCompanyPage
