import { PAGE, trackEvent } from '@/analytics'
import { ReactComponent as EnvelopeIcon } from '@/assets/icons/ic-envelope.svg'
import { ReactComponent as PhoneIcon } from '@/assets/icons/ic-phone-flip.svg'
import { useAssets } from '@/pages/asset/hooks'
import { Tab } from '@/pages/dashboard/AssetTypeTabs'
import { Order } from '@/pages/history/AssetsTable'
import { getHasAssetAnyResult } from '@/pages/history/utils'
import { Apis } from '@/providers/ApiProvider'
import { Asset, AssetFilter, AssetStatus, ReliabilitiesLabels } from '@/types/assets'
import { getNameOfAsset } from '@/utils/assets'
import formatPhoneNumber from '@/utils/phoneNumber'
import { Checkbox, Chip } from '@mui/material'
import { createColumnHelper, RowSelectionState } from '@tanstack/react-table'
import { DateTime } from 'luxon'
import { useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { TooltipFullText } from '../common/TooltipFullText'
import RatingButtons from '../VerifyAndReplace/HistoryTable/RatingButtons'
import ReliabilityLabel from '../VerifyAndReplace/HistoryTable/ReliabilityLabel'
import DownloadSelectedRows from './DownloadSelectedRows'

const columnHelper = createColumnHelper<Asset>()

export const getStatusColorStyle = (status: AssetStatus) => {
  switch (status) {
    case AssetStatus.PROCESSING:
      return {
        backgroundColor: '#FFB800',
        color: '#010417'
      }
    case AssetStatus.UPLOADED:
      return {
        color: '#010417'
      }
    case AssetStatus.READY:
      return {
        backgroundColor: '#11C14D',
        color: '#010417'
      }
    case AssetStatus.ERROR:
      return {
        backgroundColor: '#FFFFFF',
        color: '#FF5555',
        border: '1px solid #FF5555'
      }
    case AssetStatus.paid:
      return {
        backgroundColor: '#2721AE',
        color: '#FFFFFF'
      }
    default:
      return {}
  }
}

export const fetcher = async (
  page: number,
  limit: number,
  includeEmpty: boolean,
  order: Order,
  orderingColumn: Required<keyof Asset>,
  filter: AssetFilter = {}
) => {
  const result = await Apis.getAllAssets(page, limit, includeEmpty, filter, order, orderingColumn)

  return result.data
}

const useAssetTable = ({
  selectedTab,
  setTotalPage,
  setTotalResults,
  page,
  rowSelection,
  filter,
  pageNumber,
  perPageRows,
  order,
  orderingColumn,
  includeEmpty
}: {
  pageNumber?: number
  perPageRows?: number
  rowSelection?: RowSelectionState
  filter?: AssetFilter
  selectedTab?: Tab
  setTotalPage?: React.Dispatch<React.SetStateAction<number>>
  setTotalResults?: React.Dispatch<React.SetStateAction<number>>
  page?: PAGE
  includeEmpty?: boolean
  order?: Order
  orderingColumn?: Required<keyof Asset>
}) => {
  const assets = useAssets({
    pageNumber,
    perPage: perPageRows,
    filters: filter,
    isSingle: selectedTab?.isSingle,
    order,
    orderBy: orderingColumn,
    acceptEmpty: includeEmpty
  })

  useEffect(() => {
    if (!assets.data?.assets) {
      return
    }

    if (setTotalPage) {
      setTotalPage(assets.data.pagination.pages)
    }
    if (setTotalResults) {
      setTotalResults(assets.data.pagination.totalAssets)
    }
  }, [setTotalPage, setTotalResults, assets.data])

  const columns = useMemo(() => {
    // Single search columns
    if (selectedTab?.ariaLabel === 'single-searches') {
      return [
        columnHelper.display({
          id: 'select',
          header: () =>
            rowSelection === undefined ? null : (
              <DownloadSelectedRows rowSelection={rowSelection} />
            ),
          cell: ({ row }) => {
            const asset = row.original

            return (
              <Checkbox
                disabled={getHasAssetAnyResult(asset) === false}
                checked={row.getIsSelected()}
                onChange={row.getToggleSelectedHandler()}
              />
            )
          }
        }),
        columnHelper.accessor('singleEnrichment.fullName', {
          id: 'name',
          header: () => 'FULL NAME',
          cell: (info) => {
            const name = getNameOfAsset(info.row.original)

            if (!name) {
              return '-'
            }

            return (
              <Link
                to={`/asset/${info.row.original._id}`}
                className="capitalize no-underline text-text.primary"
                onClick={() => {
                  trackEvent('Select history record', { page })
                }}
              >
                {name}
              </Link>
            )
          }
        }),
        columnHelper.accessor('singleEnrichment.enrichedEmail', {
          id: 'enrichedEmail',
          header: () => 'PERSONAL EMAIL',
          cell: (info) => info.getValue() || ''
        }),
        columnHelper.accessor('singleEnrichment.enrichedWorkEmail', {
          id: 'enrichedWorkEmail',
          header: () => 'WORK EMAIL',
          cell: (info) => info.getValue() || ''
        }),
        columnHelper.accessor('singleEnrichment.enrichedPhone', {
          id: 'enrichedPhone',
          header: () => 'PERSONAL PHONE',
          cell: (info) => {
            const value = info.getValue()
            return value ? formatPhoneNumber(value) : ''
          }
        }),
        columnHelper.accessor('status', {
          id: 'status',

          header: 'STATUS',
          cell: (info) => {
            const value = info.getValue()

            if (!value) return null

            const colorStyle = getStatusColorStyle(info.getValue())
            return (
              <Chip
                label={info.getValue()}
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 500,
                  ...colorStyle
                }}
                size="small"
              />
            )
          }
        }),
        columnHelper.accessor('rating', {
          id: 'rating',
          header: 'RATING',
          cell: (info) => <RatingButtons asset={info.row.original} assets={assets} />
        }),
        columnHelper.accessor('createdAt', {
          id: 'createdAt',
          header: 'DATE',
          cell: (info) => DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_SHORT)
        })
      ]
    }

    if (selectedTab?.ariaLabel === 'bulk-lookups') {
      return [
        columnHelper.accessor('filename', {
          id: 'filename',
          header: () => 'FILE NAME',
          cell: (info) => (
            <Link
              to={`/asset/${info.row.original._id}`}
              onClick={() => {
                trackEvent('Select history record', { page })
              }}
              className="capitalize no-underline text-black"
            >
              <TooltipFullText className="text-black" limit={50}>
                {info.getValue()}
              </TooltipFullText>
            </Link>
          )
        }),
        columnHelper.accessor('label', {
          id: 'label',
          header: () => 'LABEL',
          cell: (info) => <b>{info.getValue()}</b>
        }),
        columnHelper.accessor('statistics.enrichedEmails', {
          id: 'enrichedEmails',
          header: () => <EnvelopeIcon height={12} width={16} />,
          cell: (info) =>
            (info.row.original.statistics.enrichedEmails || 0) +
            (info.row.original.statistics.enrichedWorkEmails || 0)
        }),
        columnHelper.accessor('statistics.enrichedPhones', {
          id: 'enrichedPhones',
          header: () => <PhoneIcon height={12} width={16} className="fill-text.primary" />,
          cell: (info) => info.getValue()
        }),
        columnHelper.accessor('status', {
          id: 'status',
          header: 'STATUS',
          cell: (info) => {
            const value = info.getValue()

            if (!value) return null

            const colorStyle = getStatusColorStyle(info.getValue())
            return (
              <Chip
                label={info.getValue()}
                sx={{
                  textTransform: 'capitalize',
                  fontWeight: 500,
                  ...colorStyle
                }}
                size="small"
              />
            )
          }
        }),
        columnHelper.accessor('rating', {
          id: 'rating',
          header: 'RATING',
          cell: (info) => <RatingButtons asset={info.row.original} assets={assets} />
        }),
        columnHelper.accessor('createdAt', {
          id: 'createdAt',
          header: 'DATE',
          cell: (info) => DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_SHORT)
        })
      ]
    }

    if (selectedTab?.ariaLabel === 'single-verification') {
      return [
        columnHelper.display({
          id: 'select',
          header: () =>
            rowSelection === undefined ? null : (
              <DownloadSelectedRows rowSelection={rowSelection} />
            ),
          cell: ({ row }) => {
            const asset = row.original

            return (
              <Checkbox
                disabled={getHasAssetAnyResult(asset) === false}
                checked={row.getIsSelected()}
                onChange={row.getToggleSelectedHandler()}
              />
            )
          }
        }),
        columnHelper.accessor('singleEnrichment.fullName', {
          id: 'name',
          header: () => 'FULL NAME',
          cell: (info) => {
            const name = getNameOfAsset(info.row.original)

            if (!name) {
              return '-'
            }

            return (
              <Link
                to={`/asset/${info.row.original._id}`}
                className="capitalize no-underline text-text.primary"
                onClick={() => {
                  trackEvent('Select history record', { page })
                }}
              >
                {name}
              </Link>
            )
          }
        }),
        columnHelper.accessor('singleEnrichment.company', {
          id: 'company',
          header: () => 'COMPANY',
          cell: (info) => info.getValue() || ''
        }),
        columnHelper.accessor('singleEnrichment.workEmail', {
          id: 'workEmail',
          header: () => 'PROVIDED EMAIL',
          cell: (info) => info.getValue() || ''
        }),
        columnHelper.accessor('singleEnrichment.verificationLabel', {
          id: 'verificationLabel',
          header: () => 'VERIFICATION',
          cell: (info) => {
            const label = info.getValue()
            const relaiability = ReliabilitiesLabels[label]
            return <ReliabilityLabel label={label} reliability={relaiability} />
          }
        }),
        columnHelper.accessor('singleEnrichment.enrichedWorkEmail', {
          id: 'replacementEmail',
          header: 'REPLACEMENT EMAIL',
          cell: (info) => info.getValue()
        }),
        columnHelper.accessor('rating', {
          id: 'rating',
          header: 'RATING',
          cell: (info) => <RatingButtons asset={info.row.original} assets={assets} />
        }),
        columnHelper.accessor('createdAt', {
          id: 'createdAt',
          header: 'DATE',
          cell: (info) => DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_SHORT)
        })
      ]
    }
  }, [selectedTab?.ariaLabel, rowSelection, page, assets])

  return {
    allMatches: assets.data?.assets || [],
    columns,
    revalidate: assets.refetch,
    isLoading: assets.isValidating
  }
}

export default useAssetTable
