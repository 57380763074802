import { styled, Tab, Tabs as MuiTabs } from '@mui/material'
import { TabButton } from '../../types/buttons.type'
import TabNavigationArrow from './TabNavigationArrow'

const StyledTabs = styled(MuiTabs)(({ theme }) => ({
  '& .MuiTabs-flexContainer': {
    gap: '0.75rem'
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '12px',
    height: '100%'
  },
  '& .MuiButtonBase-root': {
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    color: theme.palette.text.primary,
    flexGrow: 1,
    fontSize: '1rem',
    fontWeight: '700',
    gap: '0.75rem',
    minHeight: '56px',
    overflow: 'visible',
    padding: 0
  },
  '& .MuiTabs-scroller': {
    overflow: 'visible !important'
  }
}))

export const ProminentTabsList = ({ tabs, onChange, selectedTab }: TabButton) => {
  return (
    <StyledTabs
      className="flex mt-12 overflow-visible"
      onChange={onChange}
      value={selectedTab}
      variant="fullWidth"
    >
      {tabs.map(({ icon: Icon, label }, index) => (
        <Tab
          className="overflow-visible"
          key={label}
          label={label}
          icon={
            <>
              {Icon && <Icon />}
              {index === selectedTab && (
                <TabNavigationArrow currentTab={selectedTab} countTabs={tabs.length} />
              )}
            </>
          }
          iconPosition="start"
        />
      ))}
    </StyledTabs>
  )
}

export default ProminentTabsList
