import { Stack, SxProps, Theme } from '@mui/material'

import { ReactComponent as StopwatchIcon } from '@/assets/icons/ic-stopwatch.svg'
import { IconWithText, SxIconWithText } from '@/components/common/IconWithText'
import ResultPreviewModal from './ResultPreviewModal'

// TODO: Find a better way to define the styles using styled
const styles: Record<keyof SxIconWithText, SxProps<Theme>> = {
  container: {
    flexGrow: 1
  },
  text: null,
  icon: null
}

const { container } = styles

const SingleVerificationHeader = () => {
  return (
    <Stack className="w-full" direction="row" alignItems="center" gap={1}>
      <IconWithText icon={StopwatchIcon} style={{ container }}>
        Verify a work email in seconds
      </IconWithText>
      <ResultPreviewModal />
    </Stack>
  )
}

export default SingleVerificationHeader
