import { Box, CircularProgress, Typography } from '@mui/material'

const ProcessAssetLoader = () => {
  return (
    <Box
      alignItems="center"
      className="flex text-center mt-20 mb-10"
      flexDirection="column"
      justifyContent="center"
      gap={2}
    >
      <Box>
        <CircularProgress size={64} />
      </Box>
      <Box>
        <Typography variant="h2">Working on it!</Typography>
        <Typography variant="body1" className="mt-2" fontWeight={400}>
          This could take a minute or two.
        </Typography>
      </Box>
    </Box>
  )
}

export default ProcessAssetLoader
