import {
  Box,
  Button,
  CircularProgress,
  FormLabel,
  Stack,
  styled,
  TextareaAutosize
} from '@mui/material'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'

import DropzoneFile from './DropzoneFile/DropzoneFile'
import { Inputs } from './DropzoneFile/types'
import FormSubmitted from './FormSubmitted'
import useEmailDataSent from './hooks/useEmailDataSent'
import useTrackVerifyAndReplace from './hooks/useTrackVerifyAndReplace'
import { useIsCurrentUserActivated } from '@/reducers/user'

const Textarea = styled(TextareaAutosize)(
  () => `
  border-color: #DDE3F6;
  border-radius: 8px;
  box-sizing: border-box;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  width: 100%;

  &:focus {
    border-color: #DDE3F6;
    outline: none;
  }
`
)

const defaultValues = {
  note: '',
  file: undefined
}

const Form = () => {
  const form = useForm<Inputs>({
    defaultValues
  })

  const isActivated = useIsCurrentUserActivated()

  const trackEvent = useTrackVerifyAndReplace()
  const { sendEmail, emailSent, sendEmailError, sendingEmail } = useEmailDataSent()

  const { handleSubmit, register } = form

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    await sendEmail(data)
    trackEvent({
      eventName: 'Bulk VF - MVP Form Submission',
      fileName: data.file?.name,
      message: data.note
    })
  }
  console.log('isActivated: ', isActivated)

  if (emailSent) return <FormSubmitted />

  return (
    <FormProvider {...form}>
      {sendEmailError()}
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <Box className="mt-6">
          <DropzoneFile />
        </Box>

        <Box className="mt-8">
          <FormLabel className="text-xs uppercase text-gray-600">
            Add a Note&nbsp;
            <span
              style={{
                color: '#858798'
              }}
            >
              (Optional)
            </span>
          </FormLabel>
          <Textarea
            aria-label="minimum height"
            className="p-2 w-full"
            minRows={3}
            placeholder="Include your list goals, special instructions, concerns, or any other information you think will be helpful for our team."
            {...register('note')}
          />
        </Box>
        <Stack direction="row" spacing={2} className="mt-6" alignItems="baseline">
          <Box>
            Once enriched, we will follow-up via email with the updated list for you to download and
            start using.
          </Box>
          <Box className="ml-auto">
            {sendingEmail ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                variant="contained"
                size="large"
                sx={{ whiteSpace: 'nowrap', marginLeft: 'auto', marginRight: 'auto' }}
                type="submit"
                disabled={!isActivated}
              >
                Submit
              </Button>
            )}
          </Box>
        </Stack>
      </form>
    </FormProvider>
  )
}

export default Form
