import { api } from '@/providers/ApiProvider'
import { Inputs } from '@/components/VerifyAndReplace/BulkVerification/DropzoneFile/types'

const url = '/verify-replace'

const sendData = async (data: Inputs): Promise<boolean> => {
  const { file, note } = data
  const formData = new FormData()

  formData.append('filename', file?.name as string)
  formData.append('files', file as File)

  if (note !== undefined) formData.append('notes', note)

  const result = await api.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return result.status === 200
}

export default sendData
