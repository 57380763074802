import ArcherChip, { ArcherChipProps } from '@/components/Chip/ArcherChip'
import { AlertColor } from '@mui/material'

type Chip = ArcherChipProps & {
  color: AlertColor
  icon: React.ElementType
  label: string
}

interface ChipProps extends ArcherChipProps {
  label: string
  icon: React.ElementType
  color: AlertColor
}

const Chip = ({ color, icon: Icon, label, overrideStyle }: ChipProps) => {
  const { iconColor } = overrideStyle?.[color] ?? {}
  const iconFillColor = iconColor ? iconColor : ''

  return (
    <ArcherChip
      className="px-1 font-semibold text-[11px] h-[17px]"
      color={color}
      icon={<Icon className={`w-[11px] h-[11px] ${iconFillColor}`} />}
      label={label}
      size="small"
      variant="outlined"
      overrideStyle={overrideStyle}
    />
  )
}

export default Chip
