import { Tabs } from '@/components/common/Tabs'
import TextTabsList from '@/components/common/Tabs/navigation-styles/TextTabsList'
import { TabType } from '@/components/common/Tabs/types/tab.type'
import HistoryTable from '../HistoryTable/HistoryTable'

const tabs: TabType[] = [
  {
    label: 'Single Verification',
    content: () => <HistoryTable />
  }
]

const className = {
  body: 'border border-gray-200 shadow-none rounded-3xl',
  container: 'mb-10'
}

const HistoryTabs = () => <Tabs className={className} navigateTabs={TextTabsList} tabs={tabs} />

export default HistoryTabs
