import { Box, Typography } from '@mui/material'

import { ReactComponent as CheckedIcon } from '@/assets/icons/ic-circle-check.svg'
import NotificationEmail from '../../../pages/upload/step-components/process-matches/NotificationEmail'

const FormSubmitted = () => (
  <Box className="bg-white w-full p-[24px] mt-[24px]">
    <Box className="text-center">
      <CheckedIcon fill="#2721AE" width={48} height={48} />
      <Typography className="text-h3 font-bold mt-4 text-primary.main">All good!</Typography>
      <Typography className="mt-2 text-text.quaternary" variant="body1">
        Your file has been received. We’ll get in touch with you soon.
      </Typography>
    </Box>
    <Box className="w-full rounded-xl bg-background.tertiary mt-7">
      <NotificationEmail />
    </Box>
  </Box>
)

export default FormSubmitted
