import { filesize } from 'filesize'
import { useEffect } from 'react'
import { ErrorCode } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'

import { MAX_FILE_SIZE } from '../constants/upload-files'
import { Inputs } from '../types'

const useFileErrors = (errorCode: ErrorCode) => {
  const { setError, clearErrors } = useFormContext<Inputs>()

  const errorMessage = (message: string) => {
    setError('file', {
      type: 'manual',
      message
    })
  }

  useEffect(() => {
    if (ErrorCode.FileInvalidType === errorCode)
      return errorMessage('The selected file is not supported.')

    if (ErrorCode.FileTooLarge === errorCode)
      return errorMessage(
        `The selected file is too large. The maximum file size is ${filesize(MAX_FILE_SIZE)}.`
      )

    clearErrors('file')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode])
}

export default useFileErrors
