/* eslint-disable @typescript-eslint/ban-ts-comment */
import { trackEvent, usePageTracker } from '@/analytics'
import AppleSignInButton from '@/components/Button/AppleSignInButton'
import GoogleSignInButton from '@/components/Button/GoogleSignInButton'
import { LoadingButton } from '@/components/common/Form/LoadingButton'
import useRedirectToQueryParam from '@/hooks/useRedirectToQueryParam'
import { useAuth, useSignIn } from '@/reducers/user'
import { signIn } from '@/services/auth'
import { formatError, ServerError } from '@/utils/errors/utils'
import { emailValidator } from '@/utils/validators'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Box, Divider, Typography, useTheme } from '@mui/material'
import { AxiosError } from 'axios'
import { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { object } from 'yup'
import FormInput from '../../components/Form/Input'
import BaseLayout from './BaseLayout'
import DeleteAlert from './DeleteAlert'
import WelcomeSideScreen from './sideScreen/Welcome'

interface FormValues {
  email: string
  password: string
}

const schema = object({
  email: emailValidator
}).required()

const useRedirectToDashboard = () => {
  const navigate = useNavigate()
  const { user } = useAuth()

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true })
    }
  }, [user, navigate])
}

const trackingPayload = { page: 'Login' } as const

const ReCAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY

const SignInPage = () => {
  usePageTracker('Visits Login Page', trackingPayload)

  const theme = useTheme()

  const recaptchaRef = useRef<ReCAPTCHA>()
  const [recaptchaToken, setRecaptchaToken] = useState<string | undefined>(undefined)

  useRedirectToDashboard()

  const navigate = useNavigate()
  const { getRedirectToQueryParam } = useRedirectToQueryParam()

  const form = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(schema)
  })
  const signInAtom = useSignIn()
  const [error, setError] = useState<string | ServerError | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)

  const { control, handleSubmit, formState } = form
  const { errors } = formState

  const handleFormSubmit = async (values: FormValues) => {
    setError(undefined)
    setLoading(true)

    try {
      const response = await signIn(values, {
        headers: {
          'recaptcha-token': recaptchaToken
        }
      })

      signInAtom(response.data)

      navigate(getRedirectToQueryParam)
    } catch (error) {
      const axiosError = error as AxiosError<ServerError>
      const errorMessage = axiosError.response?.data?.msg ?? 'Invalid email or password'
      setError(errorMessage)
      recaptchaRef.current?.reset()
    }
    setLoading(false)
  }

  return (
    <>
      <DeleteAlert />
      <BaseLayout sideScreen={<WelcomeSideScreen />}>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(handleFormSubmit)} className="flex flex-col min-h-full">
            <Typography variant="h1">Login</Typography>
            <GoogleSignInButton
              className="w-full mt-10 border-[#747775] text-black"
              disabled={loading}
              page="Login"
              text="Log in with Google"
            />
            <AppleSignInButton
              className="w-full mt-4 border-[#747775] text-black"
              disabled={loading}
              page="Login"
              text="Log in with Apple"
            />
            <div className="text-center mt-6">
              <Link
                to="/auth/magic-link/request"
                style={{ textDecoration: 'none', color: theme.palette.primary.main }}
              >
                Log in with a magic link
              </Link>
            </div>
            <Box mt={4} sx={{ color: theme.palette.text.secondary }}>
              <Divider>
                <b>OR</b>
              </Divider>
            </Box>
            <FormInput
              className="font-medium text-[#515468]"
              control={control}
              name="email"
              type="email"
              label="EMAIL ADDRESS"
              controlId="fieldEmailAddress"
              rules={{ required: true }}
              error={errors.email?.message || formatError(error, 'email')}
            />
            <FormInput
              control={control}
              className="font-medium text-[#515468]"
              name="password"
              label="PASSWORD"
              type="password"
              controlId="fieldPassword"
              rules={{ required: true }}
              error={errors.password?.message || formatError(error, 'password')}
            />
            {error ? <Alert severity="error">{formatError(error)}</Alert> : null}
            <Link
              to="/auth/forget-password"
              style={{ textDecoration: 'none', color: theme.palette.primary.main }}
              onClick={() => {
                trackEvent('Forgot password', { page: 'Login' })
              }}
            >
              Forgot your password?
            </Link>
            {ReCAPTCHA_KEY ? (
              <ReCAPTCHA
                sitekey={ReCAPTCHA_KEY}
                onChange={(token) => {
                  if (token) {
                    setRecaptchaToken(token)
                  }
                }}
                // @ts-ignore
                ref={recaptchaRef}
                className="w-full mt-4"
              />
            ) : null}

            <LoadingButton
              className="w-full mt-6"
              track={{ event: 'Clicks Log In', page: 'Login' }}
            >
              Continue
            </LoadingButton>
            <div className="mx-auto text-center text-sm font-medium mt-8 flex flex-row">
              <p>Don&apos;t have an account yet?</p>
              <Link
                to="/auth/sign-up"
                className="mt-0.5 ml-4"
                style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
              >
                Sign up
              </Link>
            </div>
          </form>
        </FormProvider>
      </BaseLayout>
    </>
  )
}

export default SignInPage
