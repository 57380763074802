import { ReactComponent as DownloadIcon } from '@/assets/icons/ic-download.svg'
import { ReactComponent as ListIcon } from '@/assets/icons/ic-list.svg'
import { IconWithText } from '@/components/common/IconWithText'
import Form from '@/components/VerifyAndReplace/BulkVerification/Form'
import { Button, Stack } from '@mui/material'
import useTrackVerifyAndReplace from './hooks/useTrackVerifyAndReplace'

const TEMPLATE_URL = '/assets/example-verify-and-replace.xlsx'

const BulkVerification = () => {
  const trackEvent = useTrackVerifyAndReplace()

  const handleClick = () => {
    trackEvent({ eventName: 'Clicks - Download VF Template' })
  }

  return (
    <>
      <Stack direction="row" spacing={2}>
        <IconWithText icon={ListIcon}>Verify an entire list of work emails</IconWithText>
        <Button
          className="ml-auto bg-transparent"
          download
          href={TEMPLATE_URL}
          LinkComponent="a"
          size="small"
          startIcon={<DownloadIcon className="fill-primary.main" />}
          variant="text"
          onClick={handleClick}
        >
          Download template
        </Button>
      </Stack>
      <Form />
    </>
  )
}

export default BulkVerification
