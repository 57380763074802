import { Stack, SvgIcon, SxProps, Theme, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'

export type SxIconWithText = {
  container?: SxProps<Theme>
  text?: SxProps<Theme>
  icon?: SxProps<Theme>
}

interface IconAndTextProps {
  children: React.ReactNode
  icon?: React.ElementType
  style?: SxIconWithText
  variant?: Variant
}

const IconWithText = ({
  children,
  icon: Icon,
  style: { container, text, icon: styleIcon } = {},
  variant = 'h3'
}: IconAndTextProps) => {
  return (
    <Stack direction="row" alignItems="center" gap={1} sx={{ ...container }}>
      {Icon && <SvgIcon inheritViewBox component={Icon} sx={{ ...styleIcon }} />}
      <Typography variant={variant} className="hidden md:block" sx={{ ...text }}>
        {children}
      </Typography>
    </Stack>
  )
}

export default IconWithText
