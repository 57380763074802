import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import './Dashboard.scss'
import { useAuthGuardPageTracker } from '@/analytics'
import { useAuth } from '@/reducers/user'

const trackingPayload = { page: 'Dashboard' } as const

const DashboardPage = () => {
  const { user, isLoading } = useAuth()
  useAuthGuardPageTracker('Visits Dashboard Page', trackingPayload)

  const navigate = useNavigate()

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!user) {
      return
    }

    navigate('/lookup/company')
  }, [navigate, isLoading, user])

  return <Outlet />
}

export default DashboardPage
