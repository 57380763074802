import { Button } from '@mui/material'
import { useState } from 'react'

import SingleVerificationForm from './SingleVerificationForm'
import SingleVerificationHeader from './SingleVerificationHeader'

import { useAsset, useVerifyAndReplaceAssets } from '@/pages/asset/hooks'
import ProcessAssetLoader from './ProcessAssetLoader'
import SingleVerificationResult from './SingleVerificationResult'
import useProcessingAsset from '../hooks/useProcessingAsset'
import { trackEvent } from '@/analytics'

const SingleVerificationContainer = () => {
  const [assetId, setAssetId] = useState('')
  const { data } = useAsset(assetId)
  const { refetch } = useVerifyAndReplaceAssets()

  const { isProcessing, isReady, startProcessing } = useProcessingAsset(data?.asset)

  const processAsset = (assetId: string) => {
    refetch()
    setAssetId(assetId)
    startProcessing()
  }

  if (isProcessing) return <ProcessAssetLoader />

  if (isReady && data) {
    const handleNewVerification = () => {
      trackEvent('Clicks - New Verification', {
        email: data.asset.singleEnrichment.enrichedWorkEmail,
        fullName: data.asset.singleEnrichment.fullName,
        company: data.asset.singleEnrichment.company,
        industry: '', // TODO: Add industry to the form
        location: '' // TODO: Add location to the form
      })

      setAssetId('')
    }

    return (
      <SingleVerificationResult asset={data.asset}>
        <Button variant="contained" size="large" onClick={handleNewVerification}>
          New Verification
        </Button>
      </SingleVerificationResult>
    )
  }

  return (
    <>
      <SingleVerificationHeader />
      <SingleVerificationForm onResult={processAsset} />
    </>
  )
}

export default SingleVerificationContainer
