import { ReactComponent as CheckCircleIcon } from '@/assets/icons/ic-circle-check.svg'
import { ReactComponent as WelcomeImg } from '@/assets/images/welcome.svg'

const WelcomeSideScreen = () => {
  return (
    <div className="w-full bg-light px-8 flex flex-col items-center justify-center">
      <WelcomeImg />
      <div className="mt-14 flex flex-col gap-8">
        <div className="flex items-center p-6">
          <CheckCircleIcon className="w-6" />
          <div className=" ml-6 font-medium text-xl flex-1">
            Hit quotas faster with the right information
          </div>
        </div>
        <div className="flex items-center p-6">
          <CheckCircleIcon className="w-6" />
          <div className=" ml-6 font-medium text-xl flex-1">
            Enrich rapidly and get back to prospecting
          </div>
        </div>
        <div className="flex items-center p-6">
          <CheckCircleIcon className="w-6" />
          <div className=" ml-6 font-medium text-xl flex-1">
            Spend your time selling instead of learning the data industry
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeSideScreen
