import { Alert } from '@mui/material'
import { useState } from 'react'

import sendEmailData from '@/components/VerifyAndReplace/BulkVerification/services/send-email'
import { Inputs } from '../DropzoneFile/types'

const useEmailDataSent = () => {
  const [sendingEmail, setSendingSending] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const sendEmail = async (data: Inputs) => {
    try {
      setSendingSending(true)

      const result = await sendEmailData(data)

      if (result) setEmailSent(result)
    } catch (error) {
      setError('Failed to send data. Please try again.')
    } finally {
      setSendingSending(false)
    }
  }

  const sendEmailError = () => {
    if (!error) return null

    return (
      <Alert className="w-full mt-4" severity="error">
        {error}
      </Alert>
    )
  }

  return {
    emailSent,
    sendEmail,
    sendEmailError,
    sendingEmail
  }
}

export default useEmailDataSent
