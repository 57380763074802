import { Typography } from '@mui/material'

interface PageSubtitleProps {
  children: string
}

const PageSubtitle = ({ children }: PageSubtitleProps) => {
  return (
    <Typography className="text-text.quaternary text-base font-medium mt-3" variant="h2">
      {children}
    </Typography>
  )
}
export default PageSubtitle
