import FormInputForAdvancedCompanySearch from '@/components/Form/Input/InputForAdvancedSearch'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import useTrackVerifyAndReplace from '../BulkVerification/hooks/useTrackVerifyAndReplace'

// TODO: Make this reusable (QuickCompanyForm.tsx)
const AdvancedSearch = () => {
  const [expanded, setExpanded] = useState(false)

  const form = useFormContext()
  const trackEvent = useTrackVerifyAndReplace()

  if (!form) throw new Error('The form context can not be used outside of the FormProvider')

  const { control, formState } = form

  const handleChange = () => {
    setExpanded(!expanded)
    !expanded && trackEvent({ eventName: 'Clicks - Advanced Search' })
  }

  return (
    <Accordion className="w-full rounded-md" onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box className="flex flex-row">
          <Typography className="mx-auto pl-1 sm:pl-4 uppercase text-xs sm:text-sm font-extrabold w-32 sm:w-44 my-auto">
            Advanced Search
          </Typography>
          <Box
            className="rounded-full flex items-center sm:px-2 w-36 sm:w-48 h-7 my-auto "
            sx={{
              background:
                'linear-gradient(74.37deg, #A7A4EE 6.07%, #352ED8 42.6%, #4052F0 84.16%, #04137D 126.99%)'
            }}
          >
            <Typography color="white" className="text-xs sm:text-sm font-bold sm:p-2 mx-auto">
              Get 20% more matches
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormInputForAdvancedCompanySearch
              formState={formState}
              control={control}
              name="industry"
              tooltipText="Add industry info as you would in a Google search "
              label="COMPANY INDUSTRY"
              controlId="fieldIndustry"
              className="sm:pl-4"
              placeholder="e.g. IT Services"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormInputForAdvancedCompanySearch
              control={control}
              formState={formState}
              name="location"
              tooltipText="Enter the name of a Country, State, Region, City, or Town for better results. Please do not enter a direct address or zip code."
              label="COMPANY LOCATION"
              placeholder="California"
              controlId="fieldLocation"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default AdvancedSearch
