import { VERIFY_AND_REPLACE } from '@/constants/routes-paths'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TuneIcon from '@mui/icons-material/Tune'
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  styled,
  Switch,
  SwitchProps,
  TextField,
  Typography
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

import { ReactComponent as IcUploadFrom } from '@/assets/icons/ic-upload-from.svg'
import { Asset, AssetFilter } from '@/types/assets'
import FilterBar from './FilterBar'

import { PAGE, trackEvent, useAuthGuardPageTracker } from '@/analytics'
// import VerifyEmailBanner from '@/components/Auth/VerifyEmailBanner'
import useAssetTable from '@/components/Tables/assetTable'
import { useIsCurrentUserActivated } from '@/reducers/user'
import { RowSelectionState } from '@tanstack/react-table'
import { useForm } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import AssetTypeTabs, { useSelectedTab } from '../dashboard/AssetTypeTabs'
import Banners from '../dashboard/Banners'
import AssetsTable, { Order } from './AssetsTable'
import useHandlePageChange from './handlePageChange'
import './History.scss'
import MobileBulkAssetsTable from './MobileBulkAssetsTable'
import MobileSingleAssetsTable from './MobileSingleAssetsTable'
import NewVerifyEmailBanner from '@/components/Auth/NewVerifyEmailBanner'

const trackingPayload = { page: 'History' } as const

export const BulkLookupsInactiveUser = ({
  page,
  className
}: {
  page: PAGE
  className?: string
}) => {
  return (
    <Box
      className={twMerge(
        'history-list flex flex-col items-center justify-center -mx-4 gap-4',
        className
      )}
    >
      <NewVerifyEmailBanner page={page} />
    </Box>
  )
}

const CustomSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '200ms',
    '&.Mui-checked': {
      transform: 'translateX(19px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: 'primary.main',
        opacity: 1,
        border: 0
      }
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: 60,
    backgroundColor: '#DDE3F6',
    opacity: 1,
    transition: theme.transitions.create(['#DDE3F6'], {
      duration: 500
    })
  }
}))

const HistoryPage = () => {
  useAuthGuardPageTracker('Visits History Page', trackingPayload)
  const [pageNumber, setPageNumber] = useState(1)
  const isUserActivated = useIsCurrentUserActivated()
  const [perPageRows, setPerPageRows] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [, setTotalResults] = useState(0)
  const [filter, setFilter] = useState<AssetFilter>()
  const [includeEmpty, setIncludeEmpty] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const [order, setOrder] = useState<Order>('desc')
  const [orderingColumn, setOrderingColumn] = useState<Required<keyof Asset>>('createdAt')

  const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false)

  const toggleMobileDrawer = () => {
    setIsMobileDrawerOpen((current) => !current)
  }

  const { handleSubmit, reset, control, getValues, setValue } = useForm<AssetFilter>({
    defaultValues: {
      query: ''
    }
  })

  const onSubmit = (values: AssetFilter) => {
    const data = { ...values, requestedType }

    setFilter(data)

    trackEvent('Filters history', { page: 'History', params: data })

    if (isMobileDrawerOpen) {
      toggleMobileDrawer()
    }
  }

  const [selectedTab, setSelectedTab] = useSelectedTab()

  useEffect(() => {
    setOrder('desc')
    setPageNumber(1)
    setFilter({})
    setValue('query', '')
  }, [selectedTab, setValue])

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const isBulkSearchTab = selectedTab.ariaLabel === 'bulk-lookups'
  const isSingleSearchTab = selectedTab.ariaLabel === 'single-searches'
  const isSingleVersionTab = selectedTab.ariaLabel === 'single-verification'

  const requestedType = isSingleVersionTab ? 'verify-replace-work' : ['address', 'company']

  const { allMatches, columns, isLoading } = useAssetTable({
    pageNumber,
    perPageRows,
    filter: { ...filter, requestedType, single: selectedTab.isSingle },
    setTotalPage,
    setTotalResults,
    selectedTab,
    page: 'History',
    rowSelection: rowSelection,
    includeEmpty,
    order,
    orderingColumn
  })

  const onReset = useCallback(() => {
    trackEvent('Clear Filters', { page: 'History' })
    reset()
    setFilter({})

    if (isMobileDrawerOpen) {
      toggleMobileDrawer()
    }
  }, [reset, setFilter, isMobileDrawerOpen])

  const handlePageChange = useHandlePageChange(totalPage, setPageNumber)

  return (
    <>
      <Banners page="History" />
      <Container sx={{ py: 5 }}>
        <div className="flex flex-row-reverse justify-between my-auto pb-10">
          <FormControlLabel
            control={
              <CustomSwitch
                onChange={() => {
                  setIncludeEmpty((prev) => !prev)
                }}
                sx={{ m: 1 }}
              />
            }
            label={
              <Typography variant="body1" fontSize={'0.875rem'}>
                Show all attempts
              </Typography>
            }
            labelPlacement="end"
            className="mr-0"
          />
          <Typography variant="h1" color="primary.main">
            History
            <IconButton className="md:hidden float-right" onClick={toggleMobileDrawer}>
              <Badge
                variant="dot"
                invisible={
                  !getValues().minMatches &&
                  !getValues().maxMatches &&
                  requestedType?.length === 0 &&
                  !getValues().startDate &&
                  !getValues().endDate
                }
                color="primary"
              >
                <TuneIcon className="fill-primary.main" />
              </Badge>
            </IconButton>
          </Typography>
        </div>
        <Box className="mb-10">
          <FilterBar onSubmit={handleSubmit(onSubmit)} onReset={onReset} control={control} />
        </Box>

        <div className="mx-6 md:mx-0">
          <AssetTypeTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            page={'History'}
          />
        </div>
        {!isUserActivated ? (
          <BulkLookupsInactiveUser page="History" className="history-section" />
        ) : null}
        {isUserActivated && !allMatches.length && isLoading === false ? (
          <Box className="history-list history-section flex flex-col items-center justify-center -mx-4 gap-4">
            <Typography variant="h3">There are no previous contacts enriched</Typography>
            <Typography variant="body1" fontWeight={400}>
              Here you will see all of your new contact information.
            </Typography>
            {isBulkSearchTab && (
              <Button
                href="/lookup/address"
                variant="contained"
                size="large"
                startIcon={<IcUploadFrom />}
                className="mt-2"
              >
                Enrich a New List
              </Button>
            )}
            {isSingleSearchTab && (
              <Box className="flex gap-6">
                <Button href="/lookup/address" variant="outlined" size="large" className="mt-2">
                  Do a Lookup by Address
                </Button>
                <Button href="/lookup/company" variant="contained" size="large" className="mt-2">
                  Do a Lookup by Company
                </Button>
              </Box>
            )}
            {isSingleVersionTab && (
              <Button
                href={`/${VERIFY_AND_REPLACE}`}
                variant="contained"
                size="large"
                className="mt-2"
              >
                Verify a Work Email
              </Button>
            )}
          </Box>
        ) : null}

        {isLoading === true ? (
          <div className="flex items-center justify-center my-8">
            <CircularProgress />
          </div>
        ) : null}

        {isUserActivated && !!allMatches.length && isLoading === false ? (
          <>
            <div className="md:hidden">
              {selectedTab.ariaLabel === 'bulk-lookups' ? (
                <MobileBulkAssetsTable allMatches={allMatches} page="History" />
              ) : (
                <MobileSingleAssetsTable
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  allMatches={allMatches}
                  page="History"
                />
              )}
            </div>

            <Box className="history-list -mx-4">
              <div className="hidden md:block">
                <AssetsTable
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  allMatches={allMatches}
                  columns={columns}
                  showStatus={selectedTab.ariaLabel === 'bulk-lookups'}
                  page="History"
                  order={order}
                  setOrder={setOrder}
                  orderingColumn={orderingColumn}
                  setOrderingColumn={setOrderingColumn}
                />
              </div>

              <Stack
                className="md:mt-4"
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center">
                  <IconButton
                    color="primary"
                    size="medium"
                    aria-label="prev page"
                    component="label"
                    onClick={() => setPageNumber((current) => Math.max(current - 1, 1))}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  <TextField
                    sx={{ maxWidth: 52 }}
                    size="small"
                    value={pageNumber}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                        max: { totalPage }
                      },
                      sx: { input: { textAlign: 'center' } }
                    }}
                    onChange={handlePageChange}
                    className="mx-auto"
                  />
                  <Typography variant="subtitle2" className="pl-2">
                    / {totalPage}
                  </Typography>
                  <IconButton
                    color="primary"
                    size="medium"
                    aria-label="prev page"
                    component="label"
                    onClick={() => setPageNumber((current) => Math.min(current + 1, totalPage))}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </Stack>
                <Stack direction="row" alignItems="center" gap={2}>
                  <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
                    Item per page
                  </Typography>
                  <Select
                    sx={{ width: '100%' }}
                    value={perPageRows}
                    onChange={(event) => setPerPageRows(event.target.value as number)}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                </Stack>
              </Stack>
            </Box>
          </>
        ) : null}
      </Container>
    </>
  )
}

export default HistoryPage
