import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useCallback, useMemo, useState } from 'react'

import { AccuracyMode, MatchMode, MatchSelectConfig } from '@/types/match'

import { trackEvent, useAuthGuardPageTracker } from '@/analytics'
import { usePricingSchema } from '@/components/PriceCalculator/hooks'
import PriceCalculator from '@/components/PriceCalculator/PriceCalculator'
import { useAuth, useSignIn } from '@/reducers/user'
import { updateProfile } from '@/services/user'
import { Asset } from '@/types/assets'
import { calculatePriceWithOffset } from '@/utils'
import { debounce } from 'lodash'
import { useBillingTracker } from '../dashboard/hooks'
import MatchTypeSelection from './MatchTypeSelection'

interface SelectMatchesStepProps {
  onNext: (config: MatchSelectConfig) => void
  asset: Asset
}

const defaultMatchSelectConfig: MatchSelectConfig = {
  email: { active: true, accuracy: AccuracyMode.ALL },
  phone: { active: true, accuracy: AccuracyMode.ALL },
  workEmail: { active: true, accuracy: AccuracyMode.ALL }
} as const

export const useMatchSelectPreferences = (): MatchSelectConfig => {
  const { user, isLoading } = useAuth()
  if (!user || isLoading) return defaultMatchSelectConfig

  return {
    ...defaultMatchSelectConfig,
    email: {
      ...defaultMatchSelectConfig.email,
      active:
        user.preferences.emailMatchesSelected ||
        (user.preferences.phoneMatchesSelected === false &&
          user.preferences.emailMatchesSelected === false)
    },
    phone: {
      ...defaultMatchSelectConfig.phone,
      active:
        user.preferences.phoneMatchesSelected ||
        (user.preferences.phoneMatchesSelected === false &&
          user.preferences.emailMatchesSelected === false)
    },
    workEmail: {
      ...defaultMatchSelectConfig.workEmail,
      active:
        user.preferences.workEmailMatchesSelected ||
        (user.preferences.phoneMatchesSelected === false &&
          user.preferences.emailMatchesSelected === false &&
          user.preferences.workEmailMatchesSelected === false)
    }
  }
}

const useUpdateMatchSelectPreferences = () => {
  const refresh = useSignIn()

  return async (matchSelectConfig: MatchSelectConfig) => {
    try {
      const result = await updateProfile({
        preferences: {
          emailAccuracy: matchSelectConfig.email.accuracy,
          emailMatchesSelected: matchSelectConfig.email.active,
          phoneAccuracy: matchSelectConfig.phone.accuracy,
          phoneMatchesSelected: matchSelectConfig.phone.active,
          workEmailMatchesSelected: matchSelectConfig.workEmail.active
        }
      })
      refresh(result.data)
    } catch (error) {
      console.warn('Non-critical - Failed to update user preferences, silently eating error')
    }
  }
}

const debounceTrackEvent = debounce((payload: { value: number; total: number; price: number }) => {
  trackEvent('Adjusts calculator', { page: 'Select Matches', values: payload })
}, 300)

const trackingPayload = { page: 'Select Matches' } as const

const SelectMatches = ({ asset, onNext }: SelectMatchesStepProps) => {
  useAuthGuardPageTracker('Visits Select Matches Page', trackingPayload)
  const [matchSelectConfig, setMatchSelectConfig] = useState(useMatchSelectPreferences())
  const [matchNum, setMatchNum] = useState<Record<MatchMode, number>>({
    phone: 0,
    email: 0,
    workEmail: 0
  })
  const updateMatchConfig = useUpdateMatchSelectPreferences()
  const pricingSchema = usePricingSchema()
  const { data: billingTracker } = useBillingTracker()
  const accruedCredits = billingTracker?.accrued_credits ?? 0
  const freeCredits = billingTracker?.free_credits ?? 0

  const [saved, setSaved] = useState(false)

  const savedDisabled =
    !matchSelectConfig.email.active &&
    !matchSelectConfig.phone.active &&
    !matchSelectConfig.workEmail.active

  const handleSliderChange = useCallback(
    (val: number) => {
      debounceTrackEvent({
        value: val,
        total: asset.statistics?.totalSubmitted as number,
        price: calculatePriceWithOffset(
          val,
          pricingSchema?.data?.pricingSchema || [],
          accruedCredits,
          freeCredits
        )
      })

      setMatchNum((prevState) => ({
        ...prevState,
        [MatchMode.phone]: val
      }))
    },
    [
      accruedCredits,
      freeCredits,
      asset.statistics?.totalSubmitted,
      pricingSchema?.data?.pricingSchema
    ]
  )

  const estimatedMaxCost = useMemo(
    () =>
      calculatePriceWithOffset(
        asset.statistics?.totalSubmitted as number,
        pricingSchema?.data?.pricingSchema || [],
        accruedCredits,
        freeCredits
      ),
    [
      asset.statistics?.totalSubmitted,
      pricingSchema?.data?.pricingSchema,
      accruedCredits,
      freeCredits
    ]
  )

  const handleConfirmSelectMatch = () => {
    if (!savedDisabled && saved) {
      updateMatchConfig(matchSelectConfig)
    }
    trackEvent('Bulk - Start Processing', {
      page: 'Select Matches',
      values: {
        phone: matchSelectConfig.phone.active,
        email: matchSelectConfig.email.active,
        workEmail: matchSelectConfig.workEmail.active,
        estimatedCost: estimatedMaxCost,
        assetId: asset._id,
        lookupType: asset.requestedType
      }
    })
    onNext(matchSelectConfig)
  }

  let buttonIsDisabled = !matchSelectConfig.email.active && !matchSelectConfig.phone.active
  if (asset.requestedType === 'company') {
    buttonIsDisabled = buttonIsDisabled && !matchSelectConfig.workEmail.active
  }

  return (
    <Box mt={10}>
      <Typography variant="h1" color="primary.main" mb={1}>
        Select matches
      </Typography>
      <Typography variant="subtitle2">
        Select the type of contact information you would like to retrieve.
      </Typography>
      <Box sx={{ flex: 1, mt: 5 }}>
        <MatchTypeSelection
          asset={asset}
          matchSelectConfig={matchSelectConfig}
          setMatchSelectConfig={setMatchSelectConfig}
          setSaved={setSaved}
          saved={saved}
        />

        <PriceCalculator
          totalNum={asset.statistics?.totalSubmitted as number}
          total={calculatePriceWithOffset(
            matchNum.phone,
            pricingSchema?.data?.pricingSchema || [],
            accruedCredits,
            freeCredits
          )}
          estimatedMaxCost={estimatedMaxCost}
          handleSliderChange={handleSliderChange}
        />
        <Stack direction="row" justifyContent="space-between" mt={6}>
          <div />
          <Button
            variant="contained"
            size="large"
            onClick={handleConfirmSelectMatch}
            disabled={buttonIsDisabled}
          >
            Confirm price estimation
          </Button>
        </Stack>
      </Box>
    </Box>
  )
}
// eslint-disable-next-line prettier/prettier
export default SelectMatches
