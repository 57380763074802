import { Box, Button } from '@mui/material'
import { DropzoneState, ErrorCode } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import useFileErrors from './hooks/useFileErrors'
import { Inputs } from './types'

type Props = Pick<DropzoneState, 'getRootProps' | 'getInputProps' | 'fileRejections'>

const DropzoneImportDocument = ({ fileRejections, getRootProps, getInputProps }: Props) => {
  const { register } = useFormContext<Inputs>()

  const fileErrorCode = fileRejections[0]?.errors[0]?.code as ErrorCode
  useFileErrors(fileErrorCode)

  return (
    <Box className="max-w-xl m-auto" {...getRootProps()}>
      <Box className="font-bold text-sm">
        Drag & drop a file here, or&nbsp;
        <Button color="primary" variant="outlined">
          Browse
        </Button>
        <input
          {...getInputProps()}
          {...register('file', {
            required: 'Please select a file.'
          })}
          id="file"
          type="file"
        />
      </Box>
    </Box>
  )
}
export default DropzoneImportDocument
