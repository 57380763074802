import { Tab, Tabs as MuiTabs } from '@mui/material'
import { styled } from '@mui/material'

import { TabButton } from '../types/buttons.type'

const StyledTabs = styled(MuiTabs)(() => ({
  '& .MuiTabs-indicator': {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    height: '0.3rem'
  }
}))

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.quaternary,
  fontWeight: '700'
}))

export const TextTabsList = ({ tabs, onChange, selectedTab }: TabButton) => {
  return (
    <StyledTabs className="ml-6" onChange={onChange} value={selectedTab}>
      {tabs.map(({ label }) => (
        <StyledTab key={label} label={label} />
      ))}
    </StyledTabs>
  )
}

export default TextTabsList
