import { Typography, TypographyProps } from '@mui/material'

interface PageTitleProps extends TypographyProps {
  children: string
  className?: string
}

const PageTitle = ({ className, children, ...restProps }: PageTitleProps) => {
  return (
    <Typography className={`text-primary.main font-black ${className}`} variant="h1" {...restProps}>
      {children}
    </Typography>
  )
}

export default PageTitle
