import { VERIFY_AND_REPLACE } from '@/constants/routes-paths'
import MainSidebarLayout from '@/layouts/MainSidebarLayout'
import AssetPage from '@/pages/asset'
import ForgotPasswordPage from '@/pages/authentication/ForgetPassword'
import MagicLinkRequestPage from '@/pages/authentication/MagicLinkRequest'
import MagicLinkVerifyPage from '@/pages/authentication/MagicLinkVerifyPage'
import ResetPasswordPage from '@/pages/authentication/ResetPassword'
import CheckoutPage from '@/pages/checkout/CheckoutPage'
import DashboardAddressPage from '@/pages/dashboard/DashboardAddressPage'
import DashboardCompanyPage from '@/pages/dashboard/DashboardCompanyPage'
import BillingReceiptTemplate from '@/pages/internal/templates/billingReceipt'
import LetUsHandleContactPage from '@/pages/let-us-handle/Contact'
import LetUsHandleSchedulePage from '@/pages/let-us-handle/Schedule'
import LetUsHandleSuccessPage from '@/pages/let-us-handle/Success'
import LetUsHandleUploadPage from '@/pages/let-us-handle/Upload'
import BulkUploadAddressPage from '@/pages/lookup/address/bulk'
import BulkUploadCompanyPage from '@/pages/lookup/company/bulk'
import PaymentPage from '@/pages/payment'
import PlansPage from '@/pages/plans/PlansPage'
import SettingsBillingPage from '@/pages/settings/billing'
import SettingsProfilePage from '@/pages/settings/profile'
import ChangePasswordPage from '@/pages/settings/profile/ChangePasswordPage'
import CcpaPage from '@/pages/static/CCPA'
import PrivacyPage from '@/pages/static/PrivacyPolicy'
import TermsOfUsePage from '@/pages/static/TermsOfUse'
import VerifyAndReplacePage from '@/components/VerifyAndReplace/BulkVerification/VerifyAndReplacePage'
import { internalPDFTheme } from '@/styles/theme'
import { ScopedCssBaseline, ThemeProvider } from '@mui/material'
import { createBrowserRouter, Outlet } from 'react-router-dom'
import AuthLayout from '../layouts/auth'
import MainLayout from '../layouts/main'
import SignInPage from '../pages/authentication/SignIn'
import SignUpPage from '../pages/authentication/Signup'
import BillingPage from '../pages/billing/Billing'
import DashboardPage from '../pages/dashboard/Dashboard'
import HistoryPage from '../pages/history/History'
import PrivateRoutes from './PrivateRoutes'
import Notfound from '@/pages/errors/Notfound'
import InternalError from '@/pages/errors/InternalError'

const router = createBrowserRouter([
  {
    element: <PrivateRoutes />,
    children: [
      {
        path: '/',
        element: <MainSidebarLayout />,
        children: [
          {
            path: '',
            element: <DashboardPage />
          },
          {
            path: 'lookup/company',
            element: <DashboardCompanyPage />
          },
          {
            path: 'lookup/company/bulk',
            element: <BulkUploadCompanyPage />
          },
          {
            path: 'lookup/address',
            element: <DashboardAddressPage />
          },
          {
            path: 'lookup/address/bulk',
            element: <BulkUploadAddressPage />
          },
          {
            path: 'history',
            element: <HistoryPage />
          },
          {
            path: 'billing',
            element: <BillingPage />
          },
          {
            path: 'settings/profile',
            element: <SettingsProfilePage />
          },
          {
            path: 'settings/profile/change-password',
            element: <ChangePasswordPage />
          },
          {
            path: 'settings/billing',
            element: <SettingsBillingPage />
          },
          {
            path: 'asset/:id',
            element: <AssetPage />
          },
          {
            path: 'payment/:id',
            element: <PaymentPage />
          },
          {
            path: 'plans',
            element: <PlansPage />
          },
          {
            path: 'let-us-handle',
            element: <LetUsHandleUploadPage />
          },
          {
            path: 'let-us-handle/contact',
            element: <LetUsHandleContactPage />
          },
          {
            path: 'let-us-handle/schedule',
            element: <LetUsHandleSchedulePage />
          },
          {
            path: 'let-us-handle/success',
            element: <LetUsHandleSuccessPage />
          },
          {
            path: VERIFY_AND_REPLACE,
            element: <VerifyAndReplacePage />
          },
          {
            path: '*',
            element: <Notfound />
          }
        ].map((route) => ({
          ...route,
          errorElement: <InternalError />
        }))
      },
      {
        path: '/',
        element: <MainLayout />,
        children: [
          {
            path: 'checkout',
            element: <CheckoutPage />
          }
        ]
      },
      {
        path: '/',
        element: <MainSidebarLayout />,
        children: [
          {
            path: 'privacy',
            element: <PrivacyPage />
          },
          {
            path: 'privacy-requests',
            element: <CcpaPage />
          },
          {
            path: 'terms-of-use',
            element: <TermsOfUsePage />
          }
        ]
      }
    ]
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'sign-in',
        element: <SignInPage />
      },
      {
        path: 'sign-up',
        element: <SignUpPage />
      },
      {
        path: 'forget-password',
        element: <ForgotPasswordPage />
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPage />
      },
      {
        path: 'magic-link/verify',
        element: <MagicLinkVerifyPage />
      },
      {
        path: 'magic-link/request',
        element: <MagicLinkRequestPage />
      }
    ]
  },
  {
    // Routes used to render PDFs
    path: '/internal/templates',
    element: (
      <ThemeProvider theme={internalPDFTheme}>
        <ScopedCssBaseline>
          <Outlet />
        </ScopedCssBaseline>
      </ThemeProvider>
    ),
    children: [
      {
        path: 'billing-receipt/:id',
        element: <BillingReceiptTemplate />
      }
    ]
  }
])

export default router
